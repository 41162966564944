import Navbar from "../components/Navbar"
import ArrowRight from "../assets/arrow_circle_right.svg"
import { motion } from "framer-motion"
import LogoLinkedin from "../assets/logoLinkedin.svg"
import LogoMail from "../assets/logoMail.svg"
import LogoInstagram from "../assets/logoInstagram.svg"
import LogoTiktok from "../assets/logoTikTok.svg"
import LogoYoutube from "../assets/logoYoutube.svg"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

function Social() {
    return (
        <div>

            <Link to="/"><Navbar /></Link>
            <motion.div className='flex items-center justify-center h-screen'>
                <div className=" shadow-xl w-96 p-10 rounded-lg">
                    <h1 className="font-bold text-2xl">
                        Social
                    </h1>
                    <h5 className="text-sm text-gray-500 font-bold">
                        Follow us on social media to stay up to date with the latest news and updates!
                    </h5>
                    <a href="https://www.instagram.com/getpowerhour/">
                        <button className='flex justify-between bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 hover:bg-gradient-to-r hover:from-pink-600 hover:via-purple-600 hover:to-indigo-600 transition-all rounded-xl items-center px-5 py-3 mt-5 w-full'>

                            <div className='pr-5 flex items-center'>
                                <img src={LogoInstagram} className="w-5 mr-3"></img>
                                <p className='inline-block text-white font-bold'>
                                    Instagram
                                </p>
                            </div>
                            <img className="text-white fill-neutral-50 w-7 hover:animate-pulse " alt="icon" src={ArrowRight}></img>
                        </button>
                    </a>
                    <a href="https://www.tiktok.com/@getpowerhour">
                        <button className='flex justify-between bg-gray-900 hover:bg-black transition-all rounded-xl items-center px-5 py-3 mt-5 w-full'>
                            <div className='pr-5 flex items-center'>
                                <img src={LogoTiktok} className="w-5 mr-3"></img>
                                <p className='inline-block text-white font-bold'>
                                    TikTok
                                </p>
                            </div>
                            <img className="text-white fill-neutral-50 w-7 hover:animate-pulse " alt="icon" src={ArrowRight}></img>
                        </button>
                    </a>
                    <a href="https://www.youtube.com/@powerhourproductivity/">
                        <button className='flex justify-between bg-red-600 hover:bg-red-800 transition-all rounded-xl items-center px-5 py-3 mt-5 w-full'>
                            <div className='pr-5 flex items-center'>
                                <img src={LogoYoutube} className="w-5 mr-3"></img>
                                <p className='inline-block text-white font-bold'>
                                    YouTube
                                </p>
                            </div>
                            <img className="text-white fill-neutral-50 w-7 hover:animate-pulse " alt="icon" src={ArrowRight}></img>
                        </button>
                    </a>
                    <a href="https://www.linkedin.com/company/powerhour-productivity/">
                        <button className='flex justify-between bg-blue-600 hover:bg-blue-800 transition-all rounded-xl items-center px-5 py-3 mt-5 w-full'>
                            <div className='pr-5 flex items-center'>
                                <img src={LogoLinkedin} className="w-6 mr-3"></img>
                                <p className='inline-block text-white font-bold'>
                                    LinkedIn
                                </p>
                            </div>
                            <img className="text-white fill-neutral-50 w-7 hover:animate-pulse " alt="icon" src={ArrowRight}></img>
                        </button>
                    </a>
                    <a href="mailto:info@getpowerhour.com">
                        <button className='flex justify-between bg-gray-700 hover:bg-gray-800 transition-all rounded-xl items-center px-5 py-3 mt-5 w-full'>
                            <div className='pr-5 flex items-center'>
                                <img src={LogoMail} className="w-5 mr-3"></img>
                                <p className='inline-block text-white font-bold'>
                                    E-Mail
                                </p>
                            </div>
                            <img className="text-white fill-neutral-50 w-7 hover:animate-pulse " alt="icon" src={ArrowRight}></img>
                        </button>
                    </a>
                </div>

            </motion.div>
        </div >
    )
}

export default Social