import React from 'react';
import LogoStudyhour from '../../assets/LogoStudyhour.png';

const PrivacyPolicyView = () => {
    return (

        <div>
            <div className='bg-gray-800 flex items-center justify-center h-60'>
                <div className='flex flex-col items-center'>
                    <img src={LogoStudyhour} alt="Studyhour Logo" className="w-12 h-auto" />
                    <div className='text-center'>
                        <h2 className="text-2xl mt-2 text-white font-bold">Studyhour</h2>
                        <h2 className='text-xl text-white'>The awesome study app</h2>
                    </div>
                </div>
            </div>
            <div className="p-4">




                <div className="mb-8">
                    <h1 className="text-3xl font-bold">Privacy Policy</h1>
                    <h3 className="text-xl font-bold">Last updated: July 3, 2024</h3>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-2">1. Introduction</h2>
                    <p className="text-gray-700">This Privacy Policy outlines how Studyhour collects, uses, and protects your information when you use the app. By using Studyhour, you agree to the practices described in this policy.</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-2">2. Data Collection</h2>
                    <p className="text-gray-700">Studyhour does not collect, store, or transmit any personal data to external servers. All data generated by the app is stored locally on your device.</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-2">3. Data Usage</h2>
                    <p className="text-gray-700">The data stored on your device by Studyhour is used solely for the purpose of providing and improving the app’s functionality. We do not share your data with any third parties.</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-2">4. Data Security</h2>
                    <p className="text-gray-700">We take reasonable measures to protect the data stored on your device from unauthorized access or disclosure. However, we cannot guarantee absolute security.</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-2">5. Changes to This Privacy Policy</h2>
                    <p className="text-gray-700">We may update this Privacy Policy from time to time. Changes will be posted within the app, and your continued use of the app constitutes acceptance of the new policy.</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-2">6. Your Rights</h2>
                    <p className="text-gray-700">You have the right to access, modify, or delete the data stored on your device by Studyhour. Please contact us if you have any questions or concerns about your data.</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-2">7. Contact Information</h2>
                    <p className="text-gray-700">If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:contact@getpowerhour.com" className="text-blue-500">contact@getpowerhour.com</a>.</p>
                </div>

                <div>
                    <h2 className="text-xl font-bold mb-2">8. Governing Law</h2>
                    <p className="text-gray-700">This Privacy Policy is governed by and construed in accordance with the laws of Switzerland. Any disputes arising from this policy or the use of Studyhour will be subject to the exclusive jurisdiction of the courts of Switzerland.</p>
                </div>
            </div>

        </div>

    );
};

export default PrivacyPolicyView;