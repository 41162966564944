import Landing from "./pages/Landing";
import Social from "./pages/Social";
import EarlyAcessNote from "./pages/EarlyAcessNote";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';
import PrivacyPolicyView from "./pages/Studyhour/Privacy";
import StudyhourLanding from "./pages/Studyhour/StudyhourLanding";

function App() {
  return (
    <div className="App ">
      <BrowserRouter>
        <Switch>
          <Route path="/earlyAccessNote" exact>
            <EarlyAcessNote />
          </Route>
          <Route path="/" exact>
            <Landing />
          </Route>
          <Route path="/studyhour" exact>
            <StudyhourLanding />
          </Route>
          <Route path="/social" exact>
            <Social />
          </Route>
          <Route path="/studyhour/privacy">
            <PrivacyPolicyView />
          </Route>
          <Route path="/MigrationGuide" exact component={() => {
            window.location.href = 'https://mud-citrus-dca.notion.site/Migrating-from-Powerhour-to-Studyhour-029b9319926c466abcd0dd9f308845b7';
            return null;
          }
          }>
          </Route>
          <Route path="/AppStore" exact component={() => {
            window.location.href = 'https://apps.apple.com/us/app/powerhour/id6450137627';
            return null;
          }}>
          </Route>
          <Route path="/web" exact component={() => {
            window.location.href = 'https://app-powerhour.web.app/';
            return null;
          }
          }>
          </Route>
          <Route path="/PlayStore" exact component={() => {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.getpowerhour.powerhour';
            return null;
          }}>
          </Route>



        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

