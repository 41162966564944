import Navbar from "../components/Navbar"
import ArrowRight from "../assets/arrow_circle_right.svg"
import { motion } from "framer-motion"

function EarlyAcessNote() {
    return (
        <div>

            <Navbar />
            <motion.div className='flex items-center justify-center h-screen'>
                <motion.div className="bg-yellow-400 shadow-xl w-72 p-10 rounded-lg" whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1 }}
                >
                    <h1>
                        Please note:
                        This is an early access version and there may be some bugs.
                        The current version is only available as a web app, but will soon be available for IOS & Android.

                        Have fun :)
                    </h1>
                    <a href="https://app-powerhour.web.app/">
                        <button className='flex justify-between bg-blue-600 hover:bg-blue-800 transition-all rounded-xl items-center px-5 py-2 mt-5'>
                            <div className='pr-5'>
                                <p className='inline-block text-white font-bold'>
                                    Continue
                                </p>
                            </div>
                            <img className="text-white fill-neutral-50 w-7 hover:animate-pulse " alt="icon" src={ArrowRight}></img>
                        </button>
                    </a>
                </motion.div>

            </motion.div>
        </div>
    )
}

export default EarlyAcessNote