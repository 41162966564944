import ArrowRightBlack from "../assets/arrow_circle_down.svg"
import PowerhourScreenshot from "../assets/powerhourScreenshot.png"
import Navbar from "../components/Navbar"
import VectorShape from "../assets/VectorShape.svg"
import { useHistory } from 'react-router-dom'
import { motion } from "framer-motion"
import milestone from "../assets/milestone.png"
import ThreeStars from "../assets/ThreeStars.svg"
import StreakIcon from "../assets/streakIconWithNumber.png"
import XpIcon from "../assets/125XP.png"
import downloadOnAppStore from "../assets/Download_on_the_app_store.svg"
import toast, { Toaster } from 'react-hot-toast';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setConsent } from "firebase/analytics";
import cookie from "../assets/cookie.png"
import halfCookie from "../assets/halfCookie.png"
import crumbs from "../assets/crumbs.png"
import { useEffect, useState } from "react"


function Landing() {
    const history = useHistory();

    const [showCookie, setShowCookie] = useState(true)

    setConsent({
        analytics_storage: "denied",
        ad_storage: "denied"
    });

    const firebaseConfig = {
        apiKey: "AIzaSyDRhM0Z07dVEvArY5rzXHofc6aneKaosOg",
        authDomain: "powerhour-productivity.firebaseapp.com",
        projectId: "powerhour-productivity",
        storageBucket: "powerhour-productivity.appspot.com",
        messagingSenderId: "594846004824",
        appId: "1:594846004824:web:6ca2fe8675b1a90c80fc2d",
        measurementId: "G-B931NZYTY7"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);


    // Get the campaign parameter from the URL
    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    // Send the campaign parameter as an event to Firebase
    useEffect(() => {
        const campaign = getParameterByName('src');
        console.log(campaign);
        if (campaign) {
            logEvent(analytics, 'CampaignTracking', {
                campaign: campaign
            });
        }
    }, [showCookie]);

    const notify = () => toast('Powerhour will soon be available on the App Store! Please check back later.', {
        duration: 2000,
        position: 'bottom-center',

        // Styling
        style: {},
        className: '',

        // Custom Icon
        icon: '🚀',

        // Change colors of success/error/loading icon
        iconTheme: {
            primary: '#000',
            secondary: '#fff',
        },

        // Aria
        ariaProps: {
            role: 'status',
            'aria-live': 'polite',
        },
    });


    const [cookieLevel, setCookieLevel] = useState(1);

    const handleCookieChange = (event) => {
        setCookieLevel(parseInt(event.target.value));
    };


    return (
        <div className="">
            <Navbar />
            {showCookie && (
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-full md:w-fit">
                    <div className="bg-white bg-opacity-80 backdrop-blur-md border-solid border-gray-300 border-2 rounded-lg px-10 py-5 z-50 mx-4">
                        <h1 className="font-semibold text-lg my-2">C🍪🍪kies</h1>
                        <p className="text-gray-700 mb-4">
                            We know that you hate these popups, so we tried to make an interesting one. Bake your cookie:
                        </p>
                        <div className="flex justify-center h-28">
                            {
                                cookieLevel === 1 ? (
                                    <img src={cookie} alt="cookie" className="w-20 h-20" />
                                ) : cookieLevel === 2 ? (
                                    <img src={halfCookie} alt="cookie" className="w-20 h-20" />
                                ) : (
                                    <img src={crumbs} alt="cookie" className="w-20 h-20" />
                                )
                            }
                        </div>
                        <div className="slider-container">
                            <input
                                type="range"
                                min="1"
                                max="3"
                                value={cookieLevel}
                                className="slider"
                                id="myRange"
                                onChange={handleCookieChange}
                            />
                            <div className="slider-dots">
                                <span className={`slider-dot ${cookieLevel >= 1 ? 'active' : ''}`} />
                                <span className={`slider-dot ${cookieLevel >= 2 ? 'active' : ''}`} />
                                <span className={`slider-dot ${cookieLevel >= 3 ? 'active' : ''}`} />
                            </div>
                        </div>
                        <ul className="flex justify-between items-center">
                            <li className={cookieLevel === 1 ? 'text-black font-semibold' : 'text-gray-400'}>
                                <span className="text-sm">All Cookies</span>
                            </li>

                            <li className={cookieLevel === 2 ? 'text-black font-semibold' : 'text-gray-400'}>
                                <div className="text-center">
                                    <span className="text-sm underline">Recommended</span>
                                    <p className="text-xs">(Best experience)</p>
                                </div>
                            </li>
                            <li className={cookieLevel === 3 ? 'text-black font-semibold' : 'text-gray-400'}>
                                <span className="text-sm">No Cookies</span>
                            </li>
                        </ul>
                        <button className="w-full mt-10 bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md" onClick={() => {
                            setShowCookie(false)
                            setConsent({
                                analytics_storage: cookieLevel === 1 || cookieLevel === 2 ? "granted" : "denied",
                                ad_storage: cookieLevel === 1 ? "granted" : "denied"
                            });
                        }}>Continue</button>
                        <p className="text-sm my-5">By continuing you agree to our <a href="https://app-powerhour.web.app/privacy" className="underline">privacy policy</a> and the use of cookies (as choosen by you)</p>

                    </div>
                    <style>
                        {`
          .slider {
            width: 100%;
            appearance: none;
            height: 6px;
            background: #e4e7eb;
            outline: none;
            border-radius: 9999px;
            padding: 12px 0;
            margin: 12px 0;
            cursor: pointer;
          }

          .slider::-webkit-slider-thumb {
            appearance: none;
            width: 40px;
            height: 40px;
            background: #ffffff;
            border-radius: 50%;
            border: 2px solid #e4e7eb;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
          }

          .slider::-webkit-slider-thumb:hover {
            transform: scale(1.1);
          }

          .slider-dots {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          .slider-dot {
            width: 8px;
            height: 8px;
            background-color: #e4e7eb;
            border-radius: 50%;
          }

          .slider-dot.active {
            background-color: #555;
          }
        `}
                    </style>
                </div>
            )}





            <img src={VectorShape} className="top-0 absolute right-0 w-96 -z-50" alt="background"></img>
            <div className='p-12 md:grid grid-cols-2 mt-20'>
                <div className='flex items-center h-96'>
                    <div className='flex flex-wrap md:justify-start sm:justify-center'>
                        <motion.h1 className='mt-20 font-extrabold text-gray-800 text-3xl md:text-4xl lg:text-5xl text-center md:text-start'
                            initial={{ scale: 1, y: 100 }} animate={{ scale: 1, y: 0 }}
                            transition={{ type: "spring", stiffness: 50, damping: 10 }}>The app that helps you kill procrastination.</motion.h1>
                        <motion.h2 className='font-bold text-gray-500 mt-4'
                            initial={{ scale: 1, y: 100, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ type: "spring", stiffness: 50, damping: 10, delay: 0.2 }}
                        >Do you know the feeling of being overwhelmed by a big project? Powerhour helps you with that as it helps you organise your projects into
                            smaller and more achievable milestones. Our magic app then makes a fun game out of these milestones. </motion.h2>
                        <div className="flex justify-center">
                            <motion.button className='flex items-center mt-2 z-20'
                                initial={{ scale: 1, y: 50, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.3 }}>
                                <a href="https://apps.apple.com/us/app/powerhour/id6450137627">
                                    <img src={downloadOnAppStore} className="w-36" />
                                </a>
                            </motion.button>
                            <motion.button className='flex items-center mt-2 z-20'
                                initial={{ scale: 1, y: 50, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.4 }}>
                                <a href='https://play.google.com/store/apps/details?id=com.getpowerhour.powerhour&amp;gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="w-44 m-0" /></a>
                            </motion.button>
                        </div>
                        <Toaster />
                    </div>
                </div>
                <div className='flex md:justify-end justify-center h-[30rem]'>
                    <div>
                        <motion.img className='mt-20 md:mt-0 w-64 object-contain justify-self-end' src={PowerhourScreenshot} alt="screenshot of the app"
                            initial={{ scale: 1, y: 200 }} animate={{ scale: 1, y: 0 }} transition={{ type: "spring", stiffness: 200, damping: 10 }}
                        >
                        </motion.img>
                        <motion.img src={milestone} className="relative bottom-96 left-52 w-24 h-20"
                            initial={{ scale: 1, y: 100, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ type: "spring", stiffness: 150, damping: 10, delay: 0.1 }}
                        ></motion.img>
                        <motion.img src={ThreeStars} className="relative bottom-64 right-10 w-20 h-20"
                            initial={{ scale: 1, y: 200, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.2 }}
                        ></motion.img>
                    </div>
                </div>

            </div>
            <div className="flex justify-center mb-4">
                <img src={ArrowRightBlack} className=" animate-bounce invisible lg:visible w-8 -z-50" alt="arrow icon"></img>
            </div>
            <div className='bg-gray-50 py-10 md:py-20'>
                <div className=' mx-auto px-4 sm:px-6 lg:px-8'>
                    <h1 className='text-2xl font-extrabold text-center text-gray-800 mb-12 md:mb-20'>Here's how we turn your New Year's resolutions into lifelong habits:</h1>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
                        <motion.div className="flex items-center justify-center aspect-w-1 aspect-h-1" whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 50, opacity: 0 }}
                            transition={{ duration: 0.5, delay: 0 }}>
                            <div className="bg-white rounded-2xl shadow-lg overflow-hidden sm:w-full hover:shadow-2xl transition-all">
                                <div className="px-6 py-8">
                                    <img src={milestone} className="w-10 h-8 mb-5 mx-auto" alt="icon" />
                                    <h2 className="text-2xl font-bold text-gray-800 mb-3">Milestones</h2>
                                    <p className="text-base text-gray-700">Help you break down your big projects into smaller, more manageable goals.</p>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div className="flex items-center justify-center aspect-w-1 aspect-h-1" whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 50, opacity: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}>
                            <div className="bg-white rounded-2xl shadow-lg overflow-hidden sm:w-full hover:shadow-2xl transition-all">
                                <div className="px-6 py-8">
                                    <img src={StreakIcon} className="w-9 h-8 mb-5 mx-auto" alt="icon" />
                                    <h2 className="text-2xl font-bold text-gray-800 mb-3">Day streaks</h2>
                                    <p className="text-base text-gray-700">That will keep you going longer than your New Year's resolutions.</p>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div className="flex items-center justify-center aspect-w-1 aspect-h-1" whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 50, opacity: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}>
                            <div className="bg-white rounded-2xl shadow-lg overflow-hidden sm:w-full hover:shadow-2xl transition-all">
                                <div className="px-6 py-8">
                                    <img src={XpIcon} className="h-4 mt-2 mb-4 mx-auto" alt="icon" />
                                    <h2 className="text-2xl font-bold text-gray-800 mb-3">Experience Points (XP)</h2>
                                    <p className="text-base text-gray-700">Experience points (XP) give you that extra bit of motivation. </p>
                                </div>
                            </div>
                        </motion.div>

                        <motion.div className="flex items-center justify-center aspect-w-1 aspect-h-1" whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 50, opacity: 0 }}
                            transition={{ duration: 0.5, delay: 0.6 }}>
                            <div className="bg-white rounded-2xl shadow-lg overflow-hidden sm:w-full hover:shadow-2xl transition-all">
                                <div className="px-6 py-8">
                                    <img src={ThreeStars} className="h-8 mb-4 mx-auto" alt="icon" />
                                    <h2 className="text-2xl font-bold text-gray-800 mb-3">Levels</h2>
                                    <p className="text-base text-gray-700">That keep track of your progress and show you how far you've come.</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>



            <div className='flex justify-center flex-wrap w-full mt-10 font-bold text-3xl'>
                <h1>Do you want to try it?</h1>
            </div>

            <div className='flex justify-center flex-wrap mb-20'>
                <motion.button className='flex items-center mt-2 z-20'
                    initial={{ scale: 1, y: 50, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.3 }}>
                    <a href="https://apps.apple.com/us/app/powerhour/id6450137627">
                        <img src={downloadOnAppStore} className="w-36" />
                    </a>
                </motion.button>
                <motion.button className='flex items-center mt-2 z-20'
                    initial={{ scale: 1, y: 50, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.4 }}>
                    <a href='https://play.google.com/store/apps/details?id=com.getpowerhour.powerhour&amp;gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="w-44 m-0" /></a>
                </motion.button>
            </div>
            <div className="border-solid border-2 border-gray-200 md:mx-20 rounded-md"></div>
            <div className="mx-5 md:mx-20 mt-4 mb-5 text-center md:text-left">
                <h1 className="font-bold">Contact us</h1>
                <ul>
                    <li><a href="mailto:contact@getpowerhour.com" className="underline">Email</a></li>
                    <li><a href="https://www.linkedin.com/company/powerhour-productivity/" className="underline">Linkedin</a></li>
                    <li><a href="https://www.youtube.com/@powerhourproductivity/featured" className="underline">YouTube</a></li>
                </ul>
            </div>
        </div >

    )
}

export default Landing