import React from 'react'
import Logo from "../assets/logoPowerhour.png"

function Navbar() {
    return (
        <div className='flex justify-center py-3 items-center fixed top-0 w-full bg-white/80 backdrop-blur-lg z-50 shadow-white'>
            <img className="w-8" src={Logo}></img>
            <h1 className='ml-5 font-bold'>powerhour productivity</h1>
        </div>
    )
}

export default Navbar