import React from 'react';
import LogoStudyhour from '../../assets/LogoStudyhour.png';
import StudyhourHome from '../../assets/StudyhourHome.png';
import StudyhourFocus from '../../assets/StudyhourFocus.png';
import StudyhourProfile from '../../assets/StudyhourProfile.png';
import StudyhourRelax from '../../assets/StudyhourRelax.png';
import Notifications from '../../assets/Notifications.png';
import Deadlines from '../../assets/Deadlines.png';
import Relax from '../../assets/Relax.png';
import Security from '../../assets/Security.png';
import Pomodoro from '../../assets/Pomodoro.png';
import Structure from '../../assets/Structure.png';
import Statistics from '../../assets/Statistics.png';
import Badges from '../../assets/Badges.png';
import DayStreak from '../../assets/Daystreak.png';
import Reminders from '../../assets/Reminders.png';
import downloadOnAppStore from "../../assets/Download_on_the_app_store.svg"
import Mail from '../../assets/Mail.svg';
import Linkedin from '../../assets/LinkedInIcon.svg';


function StudyhourLanding() {
    return (
        <div className=''>
            <div className="fixed top-0 left-0 right-0 flex items-center justify-between py-4 px-10 md:px-14 lg:px-48  backdrop-blur-lg z-50 ">
                {/* Logo and Brand */}
                <div className="flex items-center space-x-4">
                    <img src={LogoStudyhour} className="h-7 mt-1" alt="Studyhour Logo" />
                    <h1 className="text-xl font-bold text-gray-400 cursor-pointer hover:text-blue-500 transition-colors">Studyhour</h1>
                </div>

                {/* Dropdown for mobile */}
                <div className="dropdown dropdown-end md:hidden">
                    <label tabIndex={0} className="btn btn-ghost">
                        <svg className="w-6 h-6" fill="none" stroke="gray" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                    </label>
                    <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                        <li><a href="#pricing" className="text-lg font-bold text-gray-400 cursor-pointer hover:text-blue-500">Pricing</a></li>
                        <li><a href="#features" className="text-lg font-bold text-gray-400 cursor-pointer hover:text-blue-500">Features</a></li>
                        <li><a href="https://mud-citrus-dca.notion.site/Migrating-from-Powerhour-to-Studyhour-029b9319926c466abcd0dd9f308845b7?pvs=4" className="text-lg font-bold text-gray-400 cursor-pointer hover:text-blue-500">Migration Guide</a></li>
                        <li><a href="https://apps.apple.com/us/app/studyhour/id6514319735" className="text-lg font-bold text-gray-400 cursor-pointer hover:text-blue-500">Download</a></li>
                    </ul>
                </div>

                {/* Navigation Links for desktop */}
                <div className="hidden md:flex items-center space-x-6">
                    <a href="#features" className="text-xl font-bold text-gray-400 cursor-pointer hover:text-blue-500 transition-colors">Features</a>
                    <a href="#pricing" className="text-xl font-bold text-gray-400 cursor-pointer hover:text-blue-500 transition-colors">Pricing</a>
                    <a href="https://mud-citrus-dca.notion.site/Migrating-from-Powerhour-to-Studyhour-029b9319926c466abcd0dd9f308845b7?pvs=4" className="transition-colors text-xl font-bold text-gray-400 cursor-pointer hover:text-blue-500">Migration Guide</a>
                    <a className="bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 rounded-full transition-colors" href="https://apps.apple.com/us/app/studyhour/id6514319735">Download</a>
                </div>
            </div>

            {/* Page 1 */}
            <div className="flex w-full items-center justify-center bg-gradient-to-br from-black via-gray-950 to-indigo-700">
                <div className='bg-noise-texture w-full flex items-center justify-center'>
                    <div className='px-8 md:px-14 lg:px-48 md:grid grid-cols-1 md:grid-cols-2 items-center pt-40 md:pt-20 h-screen'>
                        <div className='flex justify-center items-center mb-20'>
                            <div>
                                <h1 className="font-bold text-4xl md:text-7xl text-white">The awesome study app.</h1>
                                <h2 className="hidden md:block text-2xl mt-4 text-gray-400 font-bold">Organize your subjects, track your progress, gamify your learning, and excel.</h2>
                                <div className="flex w-full justify-center md:justify-start">
                                    <button className='flex items-center mt-5'
                                        initial={{ scale: 1, y: 50, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.3 }}>
                                        <a href="https://apps.apple.com/us/app/studyhour/id6514319735">
                                            <img src={downloadOnAppStore} className="w-36" />
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center'>
                            <img src={StudyhourHome} className='h-[25rem] md:h-[40rem]'></img>
                        </div>
                    </div>
                </div>
            </div>


            {/* Page 2 */}
            <div className="min-h-screen w-full flex flex-col items-center justify-center pt-16 bg-gray-950">
                <h1 className="font-bold text-6xl text-white my-2 px-10">Simply <p className=' text-transparent bg-clip-text bg-gradient-to-r inline-block from-purple-400 to-pink-600'>beautiful</p></h1>
                <h2 className="font-bold text-6xl text-white px-10">Smooth <p className=' text-transparent bg-clip-text bg-gradient-to-r inline-block from-purple-400 to-pink-600'>experience</p></h2>

                <div className='flex'>
                    <div className="md:hidden carousel carousel-center rounded-box max-w-sm space-x-2 p-4">
                        <div className="carousel-item">
                            <img
                                src={StudyhourFocus}
                                className="h-[30rem] md:h-[40rem] rounded-box object-contain" />
                        </div>
                        <div className="carousel-item">
                            <img
                                src={StudyhourProfile}
                                className="h-[30rem] md:h-[40rem] rounded-box object-contain" />
                        </div>
                        <div className="carousel-item">
                            <img
                                src={StudyhourRelax}
                                className="h-[30rem] md:h-[40rem] rounded-box object-contain" />
                        </div>
                    </div>
                </div>
                <div className='hidden md:flex pb-20 pt-20'>
                    <img src={StudyhourFocus} className='h-[30rem] md:h-[40rem]'></img>
                    <img src={StudyhourProfile} className='h-[30rem] md:h-[40rem]'></img>
                    <img src={StudyhourRelax} className='h-[30rem] md:h-[40rem]'></img>
                </div>
            </div>

            {/* Page 3 */}
            <h1 className="font-bold text-4xl text-gray-800 mt-20 my-2 px-10 md:px-14 lg:px-48" id='features'>Features</h1>
            <div className="min-h-screen w-full flex items-center justify-center pt-16 bg-white mb-20">
                {/* Feature grid */}

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-6 md:px-14 lg:px-48">
                    <div className="p-4 bg-gray-100 rounded-lg">
                        <img src={Structure} className='w-full mb-2'></img>
                        <h1 className="font-bold text-2xl text-gray-500">Structure</h1>
                        <p className="text-gray-400 mt-2">Keep all your subjects in one place. Add your goals, tasks, and exam dates.</p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg">
                        <img src={Statistics} className='w-full mb-2'></img>
                        <h1 className="font-bold text-2xl text-gray-500">Statistics</h1>
                        <p className="text-gray-400 mt-2">Analyze your study patterns with detailed statistics.</p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg">
                        <img src={Pomodoro} className='w-full mb-2'></img>
                        <h1 className="font-bold text-2xl text-gray-500">Pomodoro Sessions</h1>
                        <p className="text-gray-400 mt-2">Manage your study time effectively with Pomodoro sessions.</p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg">
                        <img src={DayStreak} className='w-full mb-2'></img>
                        <h1 className="font-bold text-2xl text-gray-500">Day Streaks</h1>
                        <p className="text-gray-400 mt-2">Track your daily progress and maintain study streaks.</p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg">
                        <img src={Badges} className='w-full mb-2'></img>
                        <h1 className="font-bold text-2xl text-gray-500">Badges</h1>
                        <p className="text-gray-400 mt-2">Earn rewards for your achievements and compete with friends.</p>
                    </div>

                    <div className="p-4 bg-gray-100 rounded-lg">
                        <img src={Relax} className='w-full mb-2'></img>
                        <h1 className="font-bold text-2xl text-gray-500">Relax Sessions</h1>
                        <p className="text-gray-400 mt-2">Incorporate breaks to refresh and enhance your productivity.</p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg">
                        <img src={Deadlines} className='w-full mb-2'></img>
                        <h1 className="font-bold text-2xl text-gray-500">Deadlines</h1>
                        <p className="text-gray-400 mt-2">Never miss important dates. Keep track of your deadlines.</p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg">
                        <img src={Reminders} className='w-full mb-2'></img>
                        <h1 className="font-bold text-2xl text-gray-500">Reminders</h1>
                        <p className="text-gray-400 mt-2">Get notified of tasks and study sessions to stay on track.</p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg">
                        <img src={Security} className='w-full mb-2'></img>
                        <h1 className="font-bold text-2xl text-gray-500">Completely On-Device</h1>
                        <p className="text-gray-400 mt-2">Access all features and data without needing an internet connection.</p>
                    </div>

                </div>

            </div>


            {/* Page 4 */}
            <div className="w-full items-center justify-center py-16 bg-gray-700 px-10 md:px-14 lg:px-48" id='pricing'>
                <h1 className="font-bold text-4xl text-gray-100 my-2">Pricing</h1>
                <h3 className="font-bold text-lg text-gray-100 mt-8">Studyhour is now available <h3 className='text-blue-500 inline-block'>completely free</h3>, with <h3 className='text-blue-500 inline-block'>no ads and unrestricted access to all features</h3>. Created by a student for students, it offers a premium experience at no cost. I wish you a lot of fun and success using studyhour! 🎓</h3>
            </div>


            {/* Page 5 */}
            <div>
                <footer className="footer footer-center bg-neutral text-neutral-content p-10">
                    <aside>
                        <img src={LogoStudyhour} className='w-14'></img>
                        <p className="font-bold">
                            studyhour by powerhour
                            <br />
                            The awesome study app.
                        </p>
                    </aside>
                    <nav>
                        <div className="grid grid-flow-col gap-4">
                            <a href='mailto:contact@getpowerhour.com' target="_blank">
                                <img src={Mail} className='w-6 h-6'></img>
                            </a>
                            <a href='https://www.youtube.com/@powerhourproductivity/featured' target="_blank">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    className="fill-current">
                                    <path
                                        d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                                </svg>
                            </a>
                            <a href='https://www.linkedin.com/company/powerhour-productivity/' target="_blank">
                                <img src={Linkedin} className='w-6 h-6'></img>
                            </a>
                        </div>
                    </nav>
                </footer>
            </div>

        </div>
    );
}

export default StudyhourLanding;